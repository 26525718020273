class Callbacks {
  static recordToFormHandler() {
    return function recordToForm(record) {
      return {
        name_trkey: record?.name_trkey,
        icon: record?.icon,
        search_range: record?.search_range,
        approach_time: record?.approach_time
      }
    }
  }

  static formToRecordHandler() {
    return function formToRecord(values, extra, meta) {
      return {
        name_trkey: values?.name_trkey,
        icon: values?.icon,
        search_range: values?.search_range,
        approach_time: values?.approach_time
      }
    }
  }

  static recordToStateHandler(locale) {
    return function recordToState(record) {
      return {
        title: _.get(record, `name_trkey.${locale}`)
      }
    }
  }

}

export default Callbacks

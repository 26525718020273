import React from 'react'
import { I18n } from '@front/volcanion'

import { Box, Grid, Typography, StepLabel, StepContent } from '@mui/material'
import { TextField, NumberField } from '@front/squirtle'

const Row1 = props =>
  <Grid container item xs={12} spacing={3}>
    <Grid item xs={4}>
      <TextField
        name='icon'
        label={I18n.t('icon')}
      />
    </Grid>
    <Grid item xs={4}>
      <NumberField
        name='search_range'
        label={I18n.t('vehicletype.search_range')}
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <Typography sx={{ ml: 3 }}> {I18n.t('minute.label.short')} </Typography>
        }}
        allowNull
      />
    </Grid>
    <Grid item xs={4}>
      <NumberField
        name='approach_time'
        label={I18n.t('vehicletype.approach_time')}
        InputProps={{
          inputProps: { style: { textAlign: 'right' } },
          endAdornment: <Typography sx={{ ml: 3 }}> {I18n.t('minute.label.short')} </Typography>
        }}
        allowNull
      />
    </Grid>
  </Grid>


const SettingSection = props => <>
  <StepLabel>
    <Typography variant='h5'>{I18n.t('setting.label', { count: -1 })}</Typography>
  </StepLabel>
  <StepContent>
    <Box sx={{ p: 2, boxShadow: 6, borderRadius: 3 }} >
      <Grid container rowSpacing={2} >
        <Row1 />
      </Grid>
    </Box>
  </StepContent>
</>

export default React.memo(SettingSection)
